import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Tags } from '../components/tags'
import { Arrow } from '../components/arrow'


const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="blog-header-wrapper">
          <h1 className="blog-post-header" itemProp="headline">{post.frontmatter.title}</h1>
          <div className="header-date-wrapper">
            <p className="header-date">{post.frontmatter.date}</p>
          </div>
          {<Tags tags={post.frontmatter.tags}/>}
          <hr/>
        </header>
        <section
          className="blog-post-detail"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      </article>
      <nav className="blog-post-nav">
        <ul className="nav-wrap"
        >
          <li className="nav-contents">
            {previous && (
              <Link className="nav-left" to={previous.fields.slug} rel="prev">
                <Arrow name="left" />{/* ← {previous.frontmatter.title} */}
                <div className="nav-old">Older</div>
              </Link>
            )}
          </li>
          <li className="nav-contents">
            {next && (
              <Link className="nav-right" to={next.fields.slug} rel="next">
                {/* {next.frontmatter.title} → */}
                <div className="nav-newer">Newer</div>
                <Arrow name="right" />
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
/* Vector */

