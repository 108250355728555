import React from "react"
import { Tag } from './tag'

export const Tags = ({tags}) => {
  if (!tags) return null;
  return (
    <ul
      className="tag-wrapper"
    >
      {tags.map(((tag, index) => {
        return (
          <li
            key={tag}
            className="tag-style"
          >
            <Tag tag={tag} />
          </li>
        )
      }))}
    </ul>
)
}