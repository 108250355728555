import React from "react"
import { ArrowLeft } from './ArrowLeft'
import { ArrowRight } from './ArrowRight'

export const Arrow = ({ name }) => {
    return (
        <div  className="arrow-wrap">
            {
                name === "left"
                    ? <ArrowLeft />
                    : <ArrowRight />
            }
        </div>
    )
}